var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',{staticClass:"col-md-12"},[_c('CRow',{staticClass:"p-3"},[_c('h4',{staticClass:"color-light ml-2 page_title"},[_c('span',{staticClass:"ul_text"},[_vm._v("TRA")]),_vm._v("FFIC LIST")])]),_c('CCardBody',[(_vm.loading==true)?_c('div',[_vm._v("Loading . . .")]):_c('div',[_c('CRow',[_c('CCol',[_c('CRow',{staticClass:"col-md-12"},[_c('label',[_vm._v("Total Rows : "+_vm._s(_vm.totalRows))])])],1),_c('CCol',[_c('div',{staticClass:"card-header-actions"},[_c('small',{staticClass:"text-muted"},[_c('multiselect',{staticClass:"pb-2",attrs:{"limit":2,"clear-on-select":false,"close-on-select":true,"multiple":false,"options":_vm.perPage,"preselect-first":false,"preserve-search":false,"placeholder":"PerPage"},on:{"input":function($event){return _vm.checkPage()}},model:{value:(_vm.tableperpage),callback:function ($$v) {_vm.tableperpage=$$v},expression:"tableperpage"}})],1)])])],1),_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"dark":false,"items-per-page":_vm.tableperpage,"pagination":"","sorter":"","hover":"","striped":"","border":"","small":"","fixed":"","light":"","column-filter":""},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"status_td"},[(item.status == true)?_c('span',[_vm._v("Active")]):_c('span',[_vm._v("Inactive")])])]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',{staticClass:"row ml-1"},[_c('span',{on:{"click":function($event){return _vm.edit(item)}}},[_c('CImg',{staticClass:"ml-1 img-fluid",staticStyle:{"cursor":"pointer"},attrs:{"src":"img/logo/edit.png","title":"Edit"}})],1),_c('span',{on:{"click":function($event){return _vm.update(item,'hide')}}},[_c('CImg',{staticClass:"img-fluid ml-1",staticStyle:{"cursor":"pointer"},attrs:{"src":"img/logo/delelt.png","title":"Delete"}})],1),_c('span',{on:{"click":function($event){return _vm.update(item)}}},[(item.status == true)?_c('CImg',{staticClass:"img-fluid ml-1",staticStyle:{"cursor":"pointer"},attrs:{"src":"img/logo/push.png","title":"Inactive","pressed":item.status},on:{"update:pressed":function($event){return _vm.$set(item, "status", $event)}}}):_vm._e(),(item.status == false)?_c('CImg',{staticClass:"img-fluid ml-1",staticStyle:{"cursor":"pointer"},attrs:{"src":"img/logo/play.png","title":"Active","pressed":item.status},on:{"update:pressed":function($event){return _vm.$set(item, "status", $event)}}}):_vm._e()],1)])])]}},{key:"status-filter",fn:function(ref){
var item = ref.item;
return [_vm._v(" ")]}},{key:"action-filter",fn:function(ref){
var item = ref.item;
return [_vm._v(" ")]}}])})],1)]),_c('CModal',{attrs:{"title":"Edit TrafficSource","show":_vm.ts_modal,"centered":true,"scrollable":"","color":"dark","size":"xl"},on:{"update:show":function($event){_vm.ts_modal=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('CButton',{staticStyle:{"display":"none"},attrs:{"color":"danger"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("Close")])]},proxy:true}])},[_c('ts-modal',{attrs:{"edit_tsdata":_vm.edit_tsdata,"ts_modal":_vm.ts_modal},on:{"tsmodal":_vm.closeModal}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }