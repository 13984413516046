<template>
    <CCard class="col-md-12">
      <CRow class="p-3">
        <h4 class="color-light ml-2 page_title"><span class="ul_text">TRA</span>FFIC LIST</h4> 
    </CRow>
    <CCardBody>
      <div v-if="loading==true">Loading . . .</div>
      <div v-else>
      <CRow>
        <CCol>
          <CRow class="col-md-12">
            <!-- <label>Filter :</label>
            <CInput class="col-md-8"
            @keyup="customFilter"
            v-model="filter"
            type="search"
            placeholder="Type to Search" /> -->
            <label>Total Rows : {{totalRows}}</label>
          </CRow>
        </CCol>
        <CCol>
      <div class="card-header-actions">
            <small class="text-muted"
              >
        <multiselect class="pb-2"
              :limit="2"
              :clear-on-select="false"
              :close-on-select="true"
              :multiple="false"
              :options="perPage"
              :preselect-first="false"
              :preserve-search="false"
              @input="checkPage()"
              placeholder="PerPage"
              v-model="tableperpage"
            ></multiselect></small
            ></div>
      </CCol>
    </CRow>
    <CDataTable
      :items="items"
      :fields="fields"
      :dark="false"
      :items-per-page="tableperpage"
      pagination
      sorter
      hover
      striped
      border
      small
      fixed
      light
      column-filter
    >
    <!-- column-filter -->
    <template #status="{item}"
            ><td class="status_td">
              <span v-if="item.status == true">Active</span>
              <span v-else>Inactive</span>
              </td></template
          >
    <template #action="{item}">
        <td>
          <div class="row ml-1">
          <span @click="edit(item)">
          <CImg src="img/logo/edit.png" class="ml-1 img-fluid" title="Edit"
                             style="cursor: pointer;"
                             ></CImg>
          </span>
          <span @click="update(item,'hide')">
          <CImg src="img/logo/delelt.png" class="img-fluid ml-1" title="Delete"
          style="cursor: pointer;"
          ></CImg>
          </span>
          <span  @click=update(item)>
            <CImg v-if="item.status == true" src="img/logo/push.png" class="img-fluid ml-1" title="Inactive" :pressed.sync="item.status"
          style="cursor: pointer;"
          ></CImg>
            <CImg v-if="item.status == false" src="img/logo/play.png" class="img-fluid ml-1" title="Active" :pressed.sync="item.status"
              style="cursor: pointer;"
          ></CImg>
          </span>
          </div>
          <!-- <CButton variant="outline" color="success" :pressed.sync="item.status" @click=update(item)>{{item.status ? 'Active ' : 'Deactive'}}</CButton> -->
          
        </td>
      </template>
      <!--start Custom Filter start -->
      <template #status-filter="{item}">&nbsp;</template>
      <template #action-filter="{item}">&nbsp;</template>
      <!--end Custom Filter start -->
      </CDataTable>
      </div>
       
    </CCardBody>
    <CModal
        title="Edit TrafficSource"
        :show.sync="ts_modal"
        :centered="true"
        scrollable
        color="dark"
        size="xl"
        
      >
      <ts-modal :edit_tsdata="edit_tsdata" :ts_modal="ts_modal" @tsmodal="closeModal"></ts-modal>
      <template #footer>
        <CButton @click="closeModal()" color="danger" style="display: none;">Close</CButton>
      </template>
      </CModal>
    </CCard>
</template>

<script>
import TrafficService from '../../services/trafficsourceControllservice'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import Multiselect from "vue-multiselect";
import TsModal from './NewTrafficSource.vue'

const fields = [
        { key: "_id", label: "TS ID",sortable: true },
        { key: "tsname", label: "Name",sortable: true },
        { key: "conversionUrl", label: "Postback" ,sortable: true},
        { key: "status", label: "Status",sortable: true },
        { key: "action", label: "Action" ,sortable: true}
      ]
export default {
  components:{Multiselect,TsModal},
  name: 'ListOffer',
  props:{small: Boolean},
  data() {
        return {
          ts_modal:false,
          edit_tsdata:{},
          all_data:[],
          filter: '',
          totalRows:0,
          loading:true,
          tableperpage:20,
          perPage:[20,50,100],
          edittraffic:false,
          items:[],
          useremail:'',
          fields,
          startDate: 1325376000000,
          endDate: 1330560000000  
        }
        },
        watch:{
        ts_modal(value){
          if(value == false){
          this.getallData();
          }
        }
      },
      mounted(){
        if(window.localStorage.getItem('user') && window.localStorage.getItem('status') != 'true'){
          this.$router.push('/')
        }
        else if(window.localStorage.getItem("user") == 'csp'){
          console.log('list ts')
          return;
        }
        else{
        this.useremail = window.localStorage.getItem("email");
        this.getallData();
        if(localStorage.getItem("traffic_table") != null)
        this.tableperpage = parseInt(localStorage.getItem("traffic_table"));
        }
      },
  methods: {
    closeModal(){
      this.ts_modal = false
      this.getallData();
    },
    customFilter() {
      let search = this.filter.toString().toLowerCase()
      if(search != ''){
      let f_data = this.all_data.filter(row => row.conversionUrl.match(search) || row.tsname.match(search))
      if(f_data){return this.items = f_data}
      }
      else{return this.items = this.all_data}
    },
    checkPage(){
      localStorage.setItem("traffic_table", this.tableperpage);
    },
    async getallData() {
      try {
        let response = await TrafficService.tsget()
        //   this.users = response.data.message;
        this.items = response.message;
        this.all_data = response.message;
        this.totalRows = this.items.length;
        this.loading = false;
      } catch (e) {
        // Swal.fire({title: 'Error!',text: 'connection error',icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
        // this.errorNotify("Error", "connection error", "danger");
        //   this.$router.push("Login");
      }
    },
    async update(formData, txt) {
      try {
        if (txt == "hide") {
          if (confirm("Are you sure you want to Delete?")) {
          let response = await TrafficService.tsremove({ tsid: formData._id })
          if(response.result){
          Swal.fire({title: 'Success!',text: response.message,icon: 'success',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
          }
          else{
            Swal.fire({title: 'Error!',text: response.message,icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
          }
          this.getallData();
          }
        } else {
          var status = true
            if(formData.status == true){
              status = false
            }
            else{
              status == true
            }
          formData.status = status
          let id = formData._id;
          let updated_data = {
            tsid: id,
            changePayload: formData
          };
          let response = await TrafficService.modify(updated_data)
          
          if(response.result){
          Swal.fire({title: 'Success!',text: response.message,icon: 'success',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
        }
        else{
          Swal.fire({title: 'Error!',text: response.message,icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
        }
        }
      } catch (e) {
        // Swal.fire({title: 'Error!',text: 'connection error',icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
        if (formData.isactive == false) {
          formData.isactive = true;
        } else {
          formData.isactive = false;
        }
      }
    },
    async edit(data) {
      // this.$root.$data.traffic = data;
      // this.$root.$data.edit = true;
      // this.$router.push('/ts/newts');
      this.edit_tsdata = {};
      this.ts_modal = false;
      this.ts_modal = true;
      this.edit_tsdata = data;
      this.edittraffic = true;

    },
    // setDateFilter (e, end) {
    //   if (end) {
    //     this.endDate = new Date(e.target.value).getTime()
    //   } else {
    //     this.startDate = new Date(e.target.value).getTime()
    //   }
    // }
  }

}
</script>

<style scoped>
.status_td{
   width: 3% !important;
}
</style>